import { useEffect, useState } from 'react';

import { LogEvent } from '@analytics';
import { Carousel, CarouselApi, CarouselContent, CarouselItem, Spacer } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';

import { Indicator } from '@components';
import { firstComeLabel } from '@templates/Event/FirstCome/components/Title';

interface HomeEventContent {
  url: string;
  src: string;
  element?: React.ReactNode;
  alt: string;
  width: number;
  height: number;
}

const HomeEventContents: HomeEventContent[] = [
  {
    url: '/training',
    src: '',
    element: (
      <div className="relative">
        <div className="absolute left-[6.66%] top-[15.73%]">
          <h2 className="text-new-Sub-Title text-[5.83vw]  text-white min-[500px]:text-[29.16px]">
            오직 운전선생에서만,
            <br />
            합법 방문 운전연수
          </h2>
          <Spacer className="h-6" />
          <p className="text-new-white-75 text-new-Body2-medium text-[3.89vw] min-[500px]:text-[19.44px]">
            경찰청 공인 운전학원과 함께해요.
          </p>
        </div>
        <Image
          src="/training/visit-training-banner.png"
          alt="방문연수 출시"
          width={360}
          height={178}
          sizes="(max-width: 498px) 100vw"
          className="w-full"
        />
      </div>
    ),
    alt: '신규 가입자 경품 증정 이벤트',
    width: 498,
    height: 252,
  },
  {
    url: '/event/univ/landing',
    src: '/home/event-univ.png',
    alt: '대학생 전용 운전학원 2만원 할인',
    width: 498,
    height: 252,
  },

  {
    url: '/event/first-come',
    src: '/events/first-come/first-come-banner.png',
    element: (
      <div className="relative">
        <div className="absolute left-[6.66%] top-[15.73%]">
          <div className="text-new-Sub-Title text-[5.83vw] text-white min-[500px]:text-[29.16px]">
            <div className="flex items-center gap-5">
              <h2>{firstComeLabel}</h2>
              <Image
                src="/events/first-come/red-lightning.png"
                width={11}
                height={15}
                alt="선착순 할인 이벤트 아이콘"
              />
            </div>
            <span className="text-new-DTRed-400">선착순 4만원 할인 </span>
            이벤트
          </div>
          <div className="text-new-white-50 mt-4 text-[3.89vw] min-[500px]:text-[19.44px]">
            단 한명에게만 주어지는 기회!
          </div>
        </div>
        <Image
          src="/events/first-come/first-come-banner.png"
          alt="선착순 할인 이벤트"
          width={360}
          height={178}
          className="w-full"
        />
      </div>
    ),
    alt: '신규 가입자 경품 증정 이벤트',
    width: 498,
    height: 252,
  },
  {
    url: 'event/lowest?isBack=true',
    src: '/home/event-lowest.png',
    alt: '최저가가 아니면 차액의 1.5배 보상해요',
    width: 498,
    height: 252,
  },
  {
    url: '/event/together',
    src: '/home/event-together.png',
    alt: '친구랑 같이 할인받으며 면허따기',
    width: 498,
    height: 252,
  },
];

export const HomeEventBanner = () => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    if (!api) return;

    api.scrollTo(current);
  }, [current]);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <div className="relative flex justify-center">
      <Carousel plugin={{ autoPlay: true }} setApi={setApi} opts={{ loop: true }}>
        <CarouselContent>
          {HomeEventContents.map((content, index: number) => {
            const { url, src, alt, width, element, height } = content;
            return (
              <CarouselItem key={'home-event-banner-' + index}>
                <Link
                  href={url}
                  onClick={() => {
                    LogEvent.활성화.homeCTA(`배너 - ${alt}`);
                  }}
                >
                  {element ?? (
                    <Image
                      src={src}
                      alt={alt}
                      width={width}
                      height={height}
                      quality={100}
                      sizes="(max-width: 498px) 100vw"
                    />
                  )}
                </Link>
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
      <Indicator
        currentNum={current}
        totalNum={HomeEventContents.length}
        indicator="number"
        className="absolute bottom-32 left-auto right-16 w-fit"
      />
    </div>
  );
};
