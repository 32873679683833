import { useEffect } from 'react';

import { produce } from 'immer';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist, redux } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

const EXPLORE_STORE_STORAGE_KEY = 'explore';

type ExploreState = {
  mode: {
    value: 'map' | 'list';
    /**
     * 변경을 누가 일으켰냐
     *
     * - `"user"`: 유저의 UI 조작으로 발생
     * - `"system"`: 유저가 아닌 모든 원인
     */
    trigger: 'user' | 'system';
  };
  type: 'academy' | 'simulation';
};

type ExploreAction =
  | {
      type: '모드변경';
      payload: ExploreState['mode'];
    }
  | { type: '학원타입변경'; payload: ExploreState['type'] };

const exploreStoreReducer = (state: ExploreState, action: ExploreAction) => {
  switch (action.type) {
    case '모드변경':
      return produce(state, (draft) => {
        draft.mode = action.payload;
      });
    case '학원타입변경':
      return produce(state, (draft) => {
        draft.type = action.payload;
      });
    default:
      return state;
  }
};

const getExploreStoreInitialState = (): ExploreState => {
  return {
    mode: {
      value: 'map',
      trigger: 'system',
    },
    type: 'academy',
  };
};

const isDev = process.env.NODE_ENV === 'development';
export const useExploreStore = isDev
  ? create(
      devtools(
        persist(redux(exploreStoreReducer, getExploreStoreInitialState()), {
          name: EXPLORE_STORE_STORAGE_KEY,
          storage: createJSONStorage(() => localStorage),
        }),
      ),
    )
  : create(
      persist(redux(exploreStoreReducer, getExploreStoreInitialState()), {
        name: EXPLORE_STORE_STORAGE_KEY,
        storage: createJSONStorage(() => localStorage),
      }),
    );

export const useExploreDefaultModeFeatureFlag = () => {
  const mode = useExploreStore(useShallow((s) => s.mode));
  const flag = useFeatureFlagVariantKey('explore-default-list-view');
  const dispatch = useExploreStore((s) => s.dispatch);

  useEffect(() => {
    if (flag === 'test' && mode.trigger === 'system') {
      dispatch({
        type: '모드변경',
        payload: {
          value: 'list',
          trigger: 'system',
        },
      });
    }
  }, [dispatch, flag, mode]);
};
