import dynamic from 'next/dynamic';

import { Home } from '@templates';

const Footer = dynamic(() => import('@components/Footer').then((v) => ({ default: v.Footer })));

export default function HomePage() {
  return (
    <div>
      <Home />
      <Footer />
    </div>
  );
}
