import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { LogEvent } from '@analytics';
import { isWebviewReadyState } from '@recoilState';
import { Spacer } from 'design-system';
import { useRouter } from 'next/router';

import { useWindowSize } from '@hooks';

import { HomeBanner } from './components';
import { HomeBoarding } from './components/HomeBoarding';
import { HomeEventBanner } from './components/HomeEventBanner';
import { HomeGuide } from './components/HomeGuide';

// const NUMBERS_OF_DISPLAY_ITEMS = 3.4;

// const isAppApproach = isAppApproaching();

export const Home = () => {
  const { height: innerHeight } = useWindowSize();
  const [isWebviewReady, setIsWebviewReady] = useRecoilState(isWebviewReadyState);
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/explore');
  }, []);

  useEffect(() => {
    if (!isWebviewReady) return;
    LogEvent.유입.homeView();
  }, [isWebviewReady]);

  return (
    <div style={{ minHeight: innerHeight }} className="bg-gray-150">
      <div className="bg-new-white">
        <HomeEventBanner />
      </div>
      <div className="bg-new-gray-100 relative mt-[-24px] rounded-t-[32px]">
        <HomeBoarding />
        <HomeGuide />
        <Spacer className="h-16" />
        <div className="px-16">
          <HomeBanner backgroundColor="white" indicator="dot" />
        </div>
      </div>
    </div>
  );
};
