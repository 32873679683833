import { LogEvent } from '@analytics';
import { Banner } from 'design-system';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { useExploreStore } from 'src/store/explore';
import { cn } from 'tailwind-config';

interface SliderContentProps {
  body: string;
  src: string;
  url: string;
  type: 'academy' | 'simulation';
}

const HomeCTAContents: SliderContentProps[] = [
  {
    body: '전국 운전학원\n예약하기',
    src: '/home/academy-cta.svg',
    url: '/explore',
    type: 'academy',
  },
  {
    body: '방문 운전연수\n신청하기',
    src: '/home/training-cta.svg',
    url: '/training',
    type: 'academy',
  },
];

export const HomeBoarding = () => {
  const dispatch = useExploreStore((s) => s.dispatch);

  return (
    <>
      <div className="flex justify-between gap-10 p-16 pb-10">
        {HomeCTAContents.map((content, index) => {
          const { body, src, url, type } = content;
          return (
            <motion.div
              whileTap={{ scale: 0.97 }}
              key={index}
              className={cn(
                'text-new-Section-Title flex-1 rounded-3xl p-20',
                index === 0 ? 'bg-[#FFFCA5]' : 'bg-new-white',
              )}
            >
              <Link
                key={index}
                onClick={() => {
                  dispatch({ type: '학원타입변경', payload: type });
                  if (type === 'academy') {
                    LogEvent.활성화.homeCTA('전국 운전학원 예약하기');
                  } else if (type === 'simulation') {
                    LogEvent.활성화.homeCTA('실내운전연습장 예약하기');
                  }
                }}
                href={url}
              >
                <div className="flex flex-col gap-8">
                  <div className="flex items-start justify-between">
                    <div className="text-new-Section-Title break-keep">
                      {body.split('\n').map((text, index) => (
                        <span key={'span-' + index}>
                          {text}
                          <br />
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="self-end">
                    <Image src={src} alt={body} width={64} height={64} />
                  </div>
                </div>
              </Link>
            </motion.div>
          );
        })}
      </div>
      <motion.div className="px-16 pb-40" whileTap={{ scale: 0.97 }}>
        <Link
          href="/event/recommend"
          onClick={() => {
            LogEvent.활성화.homeCTA('학원 추천');
          }}
        >
          <Banner
            size="medium"
            type="title+description"
            description="딱 맞는 학원을 추천해 드릴게요!"
            title="어느 학원이 좋을지 모르겠다면?"
            image="/home/academy-recommend-new.png"
            alt="운전선생 학원 추천 배너"
            backgroundColor="white"
          />
        </Link>
      </motion.div>
    </>
  );
};
